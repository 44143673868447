import { useParams } from "react-router-dom"
import Card from "../components/Card"
import { EditCardForm } from "../components/EditCardForm"
import { useEffect, useState } from "react"

export function EditCard({ card, formData, setFormData, file, handleEditCardSubmit }) {
  let { cardId } = useParams()
  const [ currentCard, setCurrentCard ] = useState({...card})
  useEffect(() => {
    formData.id = cardId
  }, [])

  const handleChange = (e) => {
    const { id, value } = e.target
    console.log('something changed')
    if (value === '') {
      return
    }
    if (id == 'dealVerbiage' || id == 'lot' || id == 'pricePerLot') {
      currentCard.deal[id] = value
      formData.deal[id] = value
    } else {
      currentCard[id] = value
      formData[id] = value
    }
    setCurrentCard({...currentCard})
    setFormData({...formData})
  }

  const {
    id,
    imageSource,
    name,
    price,
    available,
    description,
    deal,
  } = currentCard

  useEffect(()=>{
    console.log('I detected a change!')
    console.log(currentCard)
  },[
    id,
    imageSource,
    name,
    price,
    available,
    description,
    {...deal}
  ])

  // console.log('the card %j', currentCard)
  return (
    <div className='flex flex-wrap'>
      <div className='m-8 w-1/2'>
        <h1 className='text-5xl'>Edit Card</h1>
        <p>Working on Card Id { cardId }</p>
        <Card 
          id={id} 
          source={imageSource} 
          name={name} 
          price={ price} 
          description={description}
          available={ available} 
          deal={deal} 
        />
      </div>
      <div className='m-8 w-2/2'>
        <h1 className='text-5xl'>Card Edit Form</h1>
        <EditCardForm 
          card={ currentCard }
          formData={ formData } 
          file={ file } 
          handleChange={ handleChange }
        />
        <button className='max-w-[600px] ml-2 p-2 mt-8 bg-red-800 rounded-lg text-black font-bold w-[80%]' onClick={handleEditCardSubmit}>submit</button>
      </div>
    </div>
  )

}