export default function placeHolders (id) {
  switch (id) {
    case 'name':
      return 'Spiderus Amazicus'
    case 'description':
      return 'Descripticus de Spiderus'
    case '':
      return ''
    case 'price':
      return 'Dolorus Amontus'
    case 'image':
      return 'maginous'
    case 'available':
      return 'Avaylos Abilitos'
    case 'dealVerbiage':
      return 'Dealus for Realus'
    case 'lot':
      return 'Lotimos'
    case 'pricePerLot':
      return 'Cuantus per Tantus'
  
    default:
      return ''
  }
}