export function InputImage ({ id, title, type, placeHolderText, handleImageUpload, file }) {
  return (
    <>
      <label htmlFor={id}>
        <p className='ml-2'>{title}</p>
        <input 
          type={type} 
          id={id} 
          accept='image/png, image/jpeg' 
          onChange={handleImageUpload} 
        />
        <div className='ml-2 my-2 p-2 w-[80%] min-h-[1rem] rounded bg-slate-600 max-w-[600px] text-gray-400'>
          {file ? <img src={file} alt={placeHolderText} /> : placeHolderText}
        </div>
      </label>
    </>
  )
}