import { Navigate } from "react-router-dom";

export function Login({ 
  setPwd,
  setUser,
  errMsg,
  errRef,
  handleLogin,
  success
}) {

  return (
    <>
    {
      success ? <Navigate replace to='/' /> :
      <>      
      <p className='text-lg text-center font-bold pt-2' ref={errRef} aria-live='assertive'>{errMsg}</p>
      <form onSubmit={ handleLogin }>
        <h1 className='font-bold text-xl ml-6'>Admin Login</h1>

        <div>
          <label htmlFor='emailAddress'>
            <p className='ml-6'>Email Address</p>
          </label>
          <input className='ml-6' type='email' id='emailAddress' onChange={(e) => setUser(e.target.value)} required/>
          <label className='p-4 text-5' htmlFor='password'>
            <p className='ml-6'>Password</p>
          </label>
          <input type='password' id='password' onChange={(e) => setPwd(e.target.value)} required/>
        </div>
        <div className='w-[80%]'>
          <button className='w-full max-w-[600px] ml-6 p-2 bg-red-800 rounded-lg text-black font-bold'>Log In</button>
        </div>
      </form>
      </>
    }
    </>
  )
}