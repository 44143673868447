import React, { useRef, useState, useEffect } from 'react'
import './App.css';
import { Route, Routes, useNavigate, redirect } from 'react-router-dom';
import { Home } from './pages/Home';
import { CardList } from './pages/CardList';
import { CreateCard } from './pages/CreateCard';
import { Login } from './pages/Login';
import { NavBar } from './components/NavBar'
import { Container } from './components/Container'
import callOuts from './lib/callOuts'
import { EditCard } from './pages/EditCard';
const fileReader = new FileReader()

function App() {
  const pageList = [
    {path: '/', text: 'Home'},
    {path: '/create-card', text: 'Create Card'},
    {path: '/card-list', text: 'Card List'},
  ]

  const errRef = useRef()
  const navigate = useNavigate()

  const [user, setUser] = useState('')
  const [cardList, setCardList] = useState([])
  const [card, setCard] = useState({})
  const [password, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [userToken, setUserToken] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [formData, setFormData] = useState({
    ref: undefined,
    name: undefined,
    description: undefined,
    price: undefined,
    available: undefined,
    deal: {
      lot: undefined,
      pricePerLot: undefined,
      dealVerbiage: undefined,
    }})
  const [file, setFile] = useState()

  async function handleLogin (e) {
    e.preventDefault()
    setUser(e.target[0].value)
    setPwd(e.target[1].value)
    console.log('user and pw set', user, password)
    try {
      const { data } = await callOuts.login({ user, password })
      console.log('the response', data.accessToken)
      setUserToken(data.accessToken)
      setIsLoggedIn(true)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setSuccess(false)
      setErrMsg(`Oops something has gone terribly wrong! ${error}`)
    }
  }

  async function resetEntries () {
    const { data: currentEntries } = await callOuts.getEntries(userToken)
    console.log('the current entries %j', currentEntries)
    setCardList(currentEntries.items)
  }

  async function resetForm () {
    setFormData({
      ref: undefined,
      name: undefined,
      description: undefined,
      price: undefined,
      available: undefined,
      deal: {
        lot: undefined,
        pricePerLot: undefined,
        dealVerbiage: undefined,
      }})
  }

  async function handleDeleteMe (e) {
    try {      
      e.preventDefault()
      console.log(e.target)
      await callOuts.delete(e.target.id, userToken)
      resetEntries()
    } catch (error) {
      console.log('delete operation failed', error)
      setErrMsg('could not delete entry')
    }
  }
  
  async function handleSelectCard (e) {
    try{
      e.preventDefault()
      resetForm()
      console.log(e.target.id)
      const cardId = e.target.id
      const [cardData] = cardList.filter(cardData => cardData.id === cardId)
      setCard({...cardData})
      navigate(`/edit-card/${cardId}`)
    }
    catch (error){
      console.log('woops', error)
    }
  }

  async function handleFormInputChange (e) {
    const { id, value } = e.target
    if (id === 'dealVerbiage' || id === 'lot' || id === 'pricePerLot') {
      formData.deal[id] = value
    } else {
      formData[id] = value
    }

    setFormData(formData)
    // setRequestData(formData)
    console.log('the updated form data', formData)
    console.log('the event id', e.target.id)
    console.log('the event value', e.target.value)
  }

  // async function handleEditInputChange (e) {
  //   e.preventDefault()
  //   const { id, value } = e.target
  //   console.log('edit input change for id %s with value %s', id, value)
  //   console.log('this is the card', selectedCard)
  //   if( id === 'dealVerbiage' || id === 'lot') {
  //     selectedCard.deal[id] = value
  //   } else {
  //     selectedCard[id] = value
  //   }
  //   setSelectedCard({...selectedCard})
  //   setFormData({...selectedCard})
  // }

  async function handleImageUpload (e) {
    fileReader.onload = (fileEvent) => {
      const srcData = fileEvent.target.result;
      setFormData({
        ...formData,
        ref: srcData
      })
    }
    console.log('the target file',e.target.files[0])
    const imageUrl = fileReader.readAsDataURL(e.target.files[0])
    console.log('image url', imageUrl)
    setFile(URL.createObjectURL(e.target.files[0]))
    setFormData({
      ...formData,
      ref: imageUrl
    })
    console.log('the form data', formData)
  }

  const handleCreateCardSubmit = async (e) => {
    e.preventDefault()

    try {
      console.log('the data from the form', formData)
      const response = await callOuts.postEntry(formData, userToken)
      console.log(response.data)
      setFile()
      console.log(formData)
      resetEntries()
      resetForm()
      navigate('/card-list')
    } catch (error) {
      console.log('axios error', error)
      // setRequestError('Something has gone terribly wrong!')
    }
  }

  const handleEditCardSubmit = async (e) => {
    e.preventDefault()
    const updateParams = {}
    console.log('current form data', formData)
    for (const key of Object.keys(formData)) {
      if (formData[key] !== '' && formData[key] !== undefined) {
        updateParams[key] = formData[key]
      }
    }
    console.log('the update params', updateParams)
    try{
      await callOuts.update(updateParams, userToken)
      resetForm()
      resetEntries()
      navigate('/card-list')
    } catch(error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [])

  useEffect(() => {
    const getEntries = async (userToken) => {
      const { data } = await callOuts.getEntries(userToken)
      // console.log('the current entries %j', data.items)
      setCardList(data.items)
    }
    if (success) {
      try {
        getEntries(userToken)
      } catch (error) {
        // console.log('get entries call failed;', error)
      }
    }
  }, [success])

  useEffect(() => {
    console.log(Object.keys(formData))
    setCard({...card, ...formData})
  }, Object.keys(formData))

  return (
    <div className=' bg-black text-red-800 m-auto'>
      <NavBar pageList={ pageList } isLoggedIn={ isLoggedIn }/>
      <Container props={
          isLoggedIn ? 
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login success={ success } />}/>
              <Route path="/card-list" element={<CardList 
                cardList={ cardList } 
                handleDeleteMe={ handleDeleteMe } 
                handleSelectCard={ handleSelectCard }/>}
              />
              <Route 
                path="/create-card" element={<CreateCard 
                  userToken={ userToken } 
                  handleChange={ handleFormInputChange }
                  handleImageUpload={ handleImageUpload }
                  handleCreateCardSubmit={ handleCreateCardSubmit }
                  formData={ formData }
                  file={ file }
                  setFile={ setFile }
                  setFormData={ setFormData }
                />}
              />
              <Route path='/edit-card/:cardId' element={
                  <EditCard 
                  card={ card } 
                  formData={formData}
                  setFormData={setFormData}
                  handleImageUpload={handleImageUpload}
                  file={file}
                  handleEditCardSubmit={ handleEditCardSubmit }
                  />
                }
              />
            </Routes> 
          :
            <Routes>
              <Route path="/login" element={
                <Login               
                setPwd={ setPwd }
                setUser={ setUser }
                errMsg={ errMsg }
                errRef={ errRef }
                handleLogin={ handleLogin }
                success={ success } 
                />} 
                loader={()=> redirect('/')}
              />
              <Route path="/" element={
                <Login 
                  index
                  setPwd={ setPwd }
                  setUser={ setUser }
                  errMsg={ errMsg }
                  errRef={ errRef }
                  handleLogin={ handleLogin }
                  success={ success } 
                />}  
              />
            </Routes>
        }
        >
      </Container>
    </div>
  );
}

export default App;
