import placeHolders from '../lib/placeHolders' 

export function CardFormInput ({ dataProperty, formData, inputText, isRequired, handleChange }) {

  return (
    <>
      <label htmlFor={ dataProperty }><p className='ml-2'>{ inputText }</p></label>
      <input
        type='text'
        id={ dataProperty }
        onBlur={handleChange}
        placeholder={(formData[dataProperty] !== undefined) && (formData[dataProperty] !== '' )? formData[dataProperty] : placeHolders(dataProperty)}
        required={ isRequired }
      />
    </>
  )
}