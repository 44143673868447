import axios from 'axios'
import crypto from 'crypto'

const { HMAC_KEY, API_URL, ENTRY, POST_LOGIN, GET_ENTRIES, UPDATE_ENTRY } = require('../config.json')

const entryUrl = API_URL + ENTRY
const getEntriesUrl = API_URL + GET_ENTRIES
const login = API_URL + POST_LOGIN
const update = API_URL + UPDATE_ENTRY

const callOuts = {
  postEntry: (data, userToken) => {
    console.log('the token', userToken)
    const headers = { Authentication: userToken }
    return axios.post(entryUrl, data, { headers })
  },
  getEntries: (token) => {
    if(!token){
      throw new Error('Token not provided') 
    }
    const headers = { Authentication: token }
    console.log('sending token', token)

    return axios.get(getEntriesUrl, { headers })
  },
  login: ({ user, password }) => {
    const pwHash = crypto.createHmac('sha1', HMAC_KEY).update(JSON.stringify(password)).digest('hex')
    const data = { user, pwHash }
    const token = crypto.createHmac('sha1', HMAC_KEY).update(JSON.stringify(data)).digest('hex')
    const headers = { Authentication: token }
    return axios.post(login, data, { headers })
  },
  delete: (id, userToken) => {
    const headers = { Authentication: userToken }
    return axios.delete(`${entryUrl}/${id}`, { headers })
  },
  update: (data, userToken) => {
    const headers = { Authentication: userToken }
    return axios.post(update, data, { headers })
  },
}

export default callOuts