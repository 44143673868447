
import { useEffect, useState, } from 'react'
import { InputImage } from '../components/InputImage'
import { CardFormInput } from '../components/CardFormInput'
const fileReader = new FileReader()

export function CreateCard({ 
  handleCreateCardSubmit,
  handleImageUpload,
  handleChange,
  formData,
  file,
  setFormData,
}) {
  fileReader.onload = (fileEvent) => {
    const srcData = fileEvent.target.result;
    setFormData({
      ...formData,
      ref: srcData
    })
  }

  return (
    <>
      <h1 className='text-5xl'>Create Card</h1>
      <div className='m-8 '>
        <form>
          <h1 className='text-xl font-bold'>New Spider Entry!</h1>
          <div>
            <CardFormInput 
              dataProperty='name'
              formData={ formData }
              inputText='Spider Name'
              placeholderText='Spiderus Amazicus' 
              handleChange={ handleChange } 
              isRequired={true}
            />
            <CardFormInput 
              dataProperty='description'
              formData={ formData }
              inputText='Description'
              placeholderText='Descripticus de Spiderus' 
              handleChange={ handleChange } 
              isRequired={true}
            />
            <CardFormInput 
              dataProperty='price'
              formData={ formData }
              inputText='Price'
              placeholderText='Dolorus Amontus' 
              handleChange={ handleChange } 
              isRequired={true}
            />
            <InputImage
              id='image'
              title='Image File'
              type='file'
              placeHolderText='Imaginous'
              handleImageUpload={handleImageUpload}
              isRequired={true}
              file={file} />
            <CardFormInput 
              dataProperty='available'
              formData={ formData }
              inputText='Available'
              placeholderText='Avaylos Abilitos' 
              handleChange={ handleChange } 
              isRequired={true}
            />
            <CardFormInput 
              dataProperty='dealVerbiage'
              formData={ formData.deal }
              inputText='dealVerbiage'
              placeholderText='Dealus for Realus' 
              handleChange={ handleChange } 
              isRequired={true}
            />
            <CardFormInput 
              dataProperty='lot'
              formData={ formData.deal }
              inputText='Lot Per Deal Price'
              placeholderText='Lotimos' 
              handleChange={ handleChange } 
              isRequired={true}
            />
            <CardFormInput 
              dataProperty='pricePerLot'
              formData={ formData.deal }
              inputText='Price Per Lot'
              placeholderText='Cuantus per Tantus' 
              handleChange={ handleChange } 
              isRequired={true}
            />
          </div>
          <button className='max-w-[600px] ml-2 p-2 mt-8 bg-red-800 rounded-lg text-black font-bold w-[80%]' onClick={handleCreateCardSubmit}>submit</button>
        </form>
        {/* { formData ? <p>{ JSON.stringify(formData, null, 2) }</p>: null} */}
      </div>
    </>
  )
}