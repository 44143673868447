export default function Card({
  id,
  source,
  name,
  description,
  price,
  deal,
  key,
  available,
  handleDeleteMe,
  handleSelectCard,
}) {
  return (<>
    <div className='max-w-sm max-h-sm rounded overflow-hidden shadow-lg object-scale-down bg-black border-slate-600 m-4 min-h-[600px] w-[300px]'>
      <div className='h-[300px] w-[300px] overflow-hidden'>
        <img key={key} className='overflow-hidden' src={`${source}`} alt={name} />
      </div>
      <div className='px-6 py-4'>
        <div className='font-bold text-xl mb-2'>{`${name}`}</div>
        <p className='text-base'>{description}</p>
        <div className='flex'>
          <p className='inline-block rounded-full font-semibold'>{`$${price}`}</p>
        </div>
        {available ?         <div className='flex'>
          <p className='inline-block rounded-full font-semibold'>{`Availability: ${available}`}</p>
        </div> : ''}
        <div className='flex flex-col'>
          {
            Object.keys(deal).length > 0 ?
              <div className='flex-auto m-2'>
                <p>{deal.dealVerbiage}</p>
              </div> :
              <div className='w-[100%] h-10'></div>
          }
        </div>
      </div>
      {
        handleDeleteMe && handleSelectCard ? 
          <div className='grid align-bottom'>
            <button className='bg-red-800 p-2 m-4 rounded hover:bg-red-600' onClick={handleDeleteMe} id={id}>Delete Me</button>
            <button className='bg-red-800 p-2 m-4 rounded hover:bg-red-600' onClick={handleSelectCard} id={id}>Edit Me</button>
          </div> :
          <></>
      }
    </div>
  </>)
}