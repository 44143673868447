import { CardFormInput } from "./CardFormInput"

export function EditCardForm ({ formData, handleChange }) {

  return(
    <>
      <CardFormInput 
        dataProperty='name'
        formData={ formData }
        inputText='Spider Name'
        placeholderText='Spiderus Amazicus' 
        handleChange={ handleChange } 
        isRequired={true}
      />
      <CardFormInput 
        dataProperty='description'
        formData={ formData }
        inputText='Description'
        placeholderText='Descripticus de Spiderus' 
        handleChange={ handleChange } 
        isRequired={true}
      />
      <CardFormInput 
        dataProperty='price'
        formData={ formData }
        inputText='Price'
        placeholderText='Dolorus Amontus' 
        handleChange={ handleChange } 
        isRequired={true}
      />
      {/* <InputImage
        id='image'
        title='Image File'
        type='file'
        placeHolderText='Imaginous'
        handleImageUpload={handleImageUpload}
        file={file}
      /> */}
      <CardFormInput 
        dataProperty='available'
        formData={ formData }
        inputText='Available'
        placeholderText='Avaylos Abilitos' 
        handleChange={ handleChange } 
        isRequired={true}
      />
      <CardFormInput 
        dataProperty='dealVerbiage'
        formData={ formData }
        inputText='dealVerbiage'
        placeholderText='Dealus for Realus' 
        handleChange={ handleChange } 
        isRequired={true}
      />
      <CardFormInput 
        dataProperty='lot'
        formData={ formData }
        inputText='Lot Per Deal Price'
        placeholderText='Lotimos' 
        handleChange={ handleChange } 
        isRequired={true}
      />
      <CardFormInput 
        dataProperty='pricePerLot'
        formData={ formData }
        inputText='Price Per Lot'
        placeholderText='Cuantus per Tantus' 
        handleChange={ handleChange } 
        isRequired={true}
      />
    </>
  )
}