import { Link } from "react-router-dom";
import React, { useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import arachnoCowboy from '../assets/arachnoCowboy.svg'

export function NavBar({ pageList, isLoggedIn }) {
  const [nav, setNav] = useState(true)
  const [isBig, setIsBig] = useState(window.innerWidth > 768)

  const handleNavToggle = () => {
    setNav(!nav)
  }

  const checkIfBig = () => {
    console.log('checking if big', isBig)
    setIsBig(window.innerWidth > 768)
  }

  window.addEventListener('resize', checkIfBig)

  return (
    <nav className='flex justify-between items-center h-24 mx-auto px-4 text-white'>
      <img className='h-10 w-10' src={ arachnoCowboy } alt="" />
      <h1 className='w-full text-5xl font-bold text-red-800 m-4'>Arachmin</h1>
      {
        isLoggedIn ? 
        // navigation container
        <div>
          {/* unordered list for nav links screen > 768px */}
          <ul className='hidden md:flex'>
            {
              pageList.map(({path, text}) => {
                return <Link className='hover:bg-gray-600 p-4 flex items-center w-36 justify-center' to={ path } key={ path }>{ text }</Link>
              })
            }
          </ul>
          {/* Icon for small menu */}
          <div onClick={handleNavToggle} className='block md:hidden'>
            { !nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
          </div>
          <div className={!nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 md:hidden overflow-y-auto overflow-x-hidden' 
        : 'fixed left-[-100%] top-0 w-[80%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500'}>
            <img className='h-13 w-13' src={ arachnoCowboy } alt="" />
            <h1 className='w-full text-5xl font-bold text-red-800 m-4'>Arachmin</h1>
            <ul className='p-4'>
              {
                pageList.map(({path, text}) => {
                  return <Link onClick={handleNavToggle} className='flex p-4 border-b border-gray-600' to={ path } key={ path } >{ text }</Link>
                })
              }
            </ul>
          </div>
        </div>:
        <div>
          <ul>
            <Link className='hover:bg-gray-600' to='/login'>Login</Link>
          </ul>
          <div className='mx-12 w-12'>
          </div>
        </div>
      }
    </nav>
  )
}